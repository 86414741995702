.sg-file-handler {
  display: inline-flex;
  align-items: center;
  height: componentSize(m);
  border-radius: 6px;
  padding: spacing(xs);
  padding-right: 12px;
  cursor: default;
  max-width: 100%;

  &__icon {
    display: flex;
    margin-right: spacing(xs);

    & a {
      display: flex;
    }

    & img {
      width: 24px;
      max-height: 24px;
      border-radius: 4px;
    }
  }

  &--closable {
    padding-right: spacing(xs);
  }

  &__text {
    position: relative;
    top: 1px;
    min-width: 0;
  }

  &__close-button {
    background: none;
    border: none;
    padding: 0;
    margin-left: 6px;
  }

  &--white {
    background-color: $white;
  }

  &--gray-20 {
    background-color: $gray-20;
  }
}
