// Primary colors
$black: #000000;

$mintPrimaryDark: #24a865;
$mintPrimary: #60d399;
$mintSecondary: #9ce8c2;
$mintSecondaryLight: #e3f7ed;
$mintSecondaryUltraLight: #f0faf5;

$bluePrimaryDark: #0089e3;
$bluePrimary: #4fb3f6;
$blueSecondary: #b9e2fe;
$blueSecondaryLight: #d9f0ff;
$blueSecondaryUltraLight: #edf8ff;

// Secondary colors
$lavenderPrimaryDark: #163bf3;
$lavenderPrimary: #6d83f3;
$lavenderSecondary: #bdc7fb;
$lavenderSecondaryLight: #ebeeff;
$lavenderSecondaryUltraLight: #f2f4ff;

$peachPrimaryDark: #ff341a;
$peachPrimary: #ff7968;
$peachSecondary: #ffc7bf;
$peachSecondaryLight: #ffe8e5;
$peachSecondaryUltraLight: #fff1f0;

$mustardPrimaryDark: #c98600;
$mustardPrimary: #fbbe2e;
$mustardSecondary: #fedd8e;
$mustardSecondaryLight: #fff3d6;
$mustardSecondaryUltraLight: #fffaf0;

// Grey colors
$white: #ffffff;
$grayPrimary: #323c45;
$graySecondary: #687b8c;
$graySecondaryLight: #c3d1dd;
$graySecondaryLighter: #e1eaf1;
$graySecondaryLightest: #ebf2f7;
$graySecondaryUltraLight: #f5f8fa;

// configuration needed for buttons
$transparent: rgba(255, 255, 255, 0);
$buttonPrimaryFbColor: #3c5b96;
$buttonPrimaryFbHoverColor: #4367a9;

// New colors system ⬇️
$blue-70: #002238;
$blue-60: #014a82;
$blue-50: #0089e3;
$blue-40: #4fb3f6;
$blue-30: #b9e2fe;
$blue-20: #d9f0ff;
$blue-10: #edf8ff;

$green-70: #002e13;
$green-60: #00672e;
$green-50: #24a865;
$green-40: #60d399;
$green-30: #9ce8c2;
$green-20: #e3f7ed;
$green-10: #f0faf5;

$indigo-70: #0c114d;
$indigo-60: #133191;
$indigo-50: #163bf3;
$indigo-40: #6d83f3;
$indigo-30: #bdc7fb;
$indigo-20: #ebeeff;
$indigo-10: #f2f4ff;

$red-70: #8d1a00;
$red-60: #cf1d00;
$red-50: #ff341a;
$red-40: #ff7968;
$red-30: #ffc7bf;
$red-20: #ffe8e5;
$red-10: #fff1f0;

$yellow-70: #5b3100;
$yellow-60: #935000;
$yellow-50: #c98600;
$yellow-40: #fbbe2e;
$yellow-30: #fedd8e;
$yellow-20: #fff3d6;
$yellow-10: #fffaf0;

$gray-70: #323c45;
$gray-60: #46535f;
$gray-50: #687b8c;
$gray-40: #c3d1dd;
$gray-30: #e1eaf1;
$gray-20: #ebf2f7;
$gray-10: #f5f8fa;

// Text color tokens ⬇️
$text-white: $white;
$text-black: $black;
$text-blue-60: $blue-60;
$text-blue-40: $blue-40;
$text-green-60: $green-60;
$text-green-40: $green-40;
$text-indigo-60: $indigo-60;
$text-indigo-40: $indigo-40;
$text-red-60: $red-60;
$text-red-40: $red-40;
$text-yellow-60: $yellow-60;
$text-yellow-40: $yellow-40;
$text-gray-70: $gray-70;
$text-gray-60: $gray-60;
$text-gray-50: $gray-50;
$text-gray-40: $gray-40;

// Icon color tokens ⬇️
$icon-white: $white;
$icon-black: $black;
$icon-blue-50: $blue-50;
$icon-green-50: $green-50;
$icon-indigo-50: $indigo-50;
$icon-red-50: $red-50;
$icon-yellow-50: $yellow-50;
$icon-gray-70: $gray-70;
$icon-gray-50: $gray-50;
$icon-gray-40: $gray-40;

// this comment exists to suppress errors about these unused variables:
// $bluePrimary $bluePrimaryDark $blueSecondary $blueSecondaryLight $blueSecondaryUltraLight
// $graySecondaryLighter $lavenderPrimary $lavenderPrimaryDark $lavenderSecondary $lavenderSecondaryLight
// $lavenderSecondaryUltraLight $mintPrimaryDark $mintSecondary $mintSecondaryLight $mintSecondaryUltraLight
// $mustardPrimary $mustardPrimaryDark $mustardSecondary $mustardSecondaryLight $mustardSecondaryUltraLight
// $peachPrimaryDark $peachSecondary $peachSecondaryLight $peachSecondaryUltraLight
// $blue-10 $blue-20 $blue-30 $blue-70 $gray-10 $gray-20 $gray-30 $green-10 $green-20 $green-30
// $green-70 $icon-black $icon-blue-50 $icon-gray-40 $icon-gray-50 $icon-gray-70 $icon-green-50
// $icon-indigo-50 $icon-red-50 $icon-white $icon-yellow-50 $indigo-10 $indigo-20 $indigo-30 $indigo-70
// $red-10 $red-20 $red-30 $red-70 $text-black $text-blue-40 $text-blue-60 $text-gray-40 $text-gray-60
// $text-gray-70 $text-green-40 $text-green-60 $text-indigo-40 $text-indigo-60 $text-red-40 $text-red-60
// $text-white $text-yellow-40 $text-yellow-60 $yellow-10 $yellow-20 $yellow-30 $yellow-70

// Shadows
$shadowSmall: 0 2px 8px 0 rgba($grayPrimary, 0.2);
$shadowMedium: 0 4px 16px 0 rgba($grayPrimary, 0.2);
$shadowLarge: 0 8px 32px 0 rgba($grayPrimary, 0.2);

// Fonts
$fontFamilyProximaNova: 'ProximaNova';
$fontFamilyUntitledSerif: 'UntitledSerif';
$fontFamilyPrimary: $fontFamilyProximaNova, 'Helvetica', 'Arial', sans-serif;
$fontWeightNormal: 400;
$fontWeightBold: 700;
$fontWeightBlack: 900;

// Vertical rhythm
$baseFont: 16px;
$baseline: 1.5rem;

// Typo
$fontSizes: (
  default: 1rem /* 16px */,
  xsmall: 0.625rem /* 10px */,
  small: 0.875rem /* 14px */,
  medium: 0.9375rem /* 15px */,
  large: 3.625rem /* 58px */,
  xlarge: 4.5rem /* 72px */,
  xxlarge: 5rem /* 80px */,
  obscure: 0.75rem /* 12px */,
  standout: 1.125rem /* 18px */,
  subheadline: 1.25rem /* 20px */,
  headline: 1.5rem /* 24px */,
  subheader: 2.75rem /* 44px */,
  header: 3rem,
);

// Layout
$layoutMaximumWidth: 1400px;
$layoutDefaultPadding: 24px;

// Spacings
$sizesSetup: (
  xxs: 4px /* additional for very small components, like icons */,
  xs: 8px,
  s: 16px,
  m: 24px,
  l: 40px,
  xl: 64px,
  xxl: 104px,
  xxxl: 168px,
  xxxxl: 272px,
);

// component sizes
$componentSizesSetup: (
  xxs: 16px,
  xs: 24px,
  s: 32px,
  m: 40px,
  l: 56px,
  xl: 80px,
  xxl: 104px,
);

// Border radius
$borderRadiusDefault: 8px;
$borderRadiusLarge: $borderRadiusDefault * 1.5;
$borderRadiusSmall: $borderRadiusDefault / 2;

// Vertical stack
$sgHeaderZIndex: 101;
$overlayZIndex: 999;
$toplayerZIndex: 1000;

// Breakpoints
$breakpointMedium: 768px !default;
$breakpointLarge: 1024px !default;
$breakpointXLarge: 1280px !default;

/// @deprecated Adding values to this map should be avoided.
/// Use $responsiveBreakpointsMap
$breakpointsMap: (
  'small-only': '(max-width: #{$breakpointMedium - 1px})',
  'medium-only':
    '(min-width: #{$breakpointMedium}) and (max-width: #{$breakpointLarge - 1px})',
  'medium-down': '(max-width: #{$breakpointLarge - 1px})',
  'medium-up': '(min-width: #{$breakpointMedium})',
  'large-only': '(min-width: #{$breakpointLarge})',
  'large-up': '(min-width: #{$breakpointLarge})',
  'xlarge-up': '(min-width: #{$breakpointXLarge})',
);

// Breakpoints map for mobile first approach usage
$responsiveBreakpointsMap: (
  'md': '(min-width: #{$breakpointMedium})',
  'lg': '(min-width: #{$breakpointLarge})',
  'xl': '(min-width: #{$breakpointXLarge})',
);

// Breakpoints variants for generating responsive utility classes
$responsiveVariants: (
  '': '',
  md: 'md\\:',
  lg: 'lg\\:',
  xl: 'xl\\:',
);

// Form elements configuration
$formElementSizeM: 40px; // Standard size for elements like inputs, selects or buttons
$formElementSizeL: 56px;
$formElementDefaultBackgroundColor: $graySecondaryLightest;
$formElementDisabledBackgroundColor: $graySecondaryUltraLight;
$formElementActiveBorderColor: $graySecondaryLight;
$formElementDefaultTextColor: $black;
$formElementPlacholderTextColor: $graySecondary;
$formElementDisabledPlacholderTextColor: $graySecondaryLight;
$formElementDefaultFontSize: 16px; // fix zooming to input on focus in iOS devices
$formElementLargeFontSize: 18px;
$formElementDefaultLineHeight: 24px;
$formElementValidColor: $mintPrimary;
$formElementInvalidColor: $peachPrimary;
$formElementStandardBorderRadius: 20px;
$formElementLargeBorderRadius: 28px;

// Motion animation durations
$durationInstant: 0ms; // Text change
$durationQuick1: 80ms; // Checkboxes, Radio Buttons, Hover
$durationQuick2: 120ms; // Toggles, Tabs, Color, Fade
$durationModerate1: 180ms; // Dropdowns, Tooltips
$durationModerate2: 260ms; // Modals, Toast, Dialog, Notification
$durationGentle1: 320ms; // Bottom Sheet, Card Expand
$durationGentle2: 400ms; // Advanced Animations

$easingRegular: cubic-bezier(0.35, 0, 0.1, 1);
$easingEntry: cubic-bezier(0.1, 0, 0, 1);
$easingExit: cubic-bezier(0.3, 0, 1, 0.8);
$easingLinear: linear;

// this comment exists to suppress errors about these unused variables:
// $durationGentle2 $durationInstant $durationModerate1 $durationQuick1 $easingRegular
