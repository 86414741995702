$subjectIconBoxBackground: $blue-30;
$subjectIconBoxDarkerBackground: $blue-40;

.sg-subject-icon-box {
  border-radius: 50%;
  background-color: $subjectIconBoxBackground;
  display: inline-block;
  font-size: 0;
  line-height: 0;

  &--darker {
    background-color: $subjectIconBoxDarkerBackground;
  }
}
