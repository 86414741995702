$overlayBackgroundColorBlue: rgba($blue-40, 0.9);
$overlayBackgroundColorBlack: rgba($black, 0.4);
$includeHtml: false !default;

@if ($includeHtml) {
  .sg-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: $overlayZIndex;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    -webkit-overflow-scrolling: touch; // it fixes an issue with scrolling on iOS browsers

    &--partial {
      position: absolute;
    }

    &--black {
      background-color: $overlayBackgroundColorBlack;
    }

    &--blue {
      background-color: $overlayBackgroundColorBlue;
    }
  }
}
