$includeHtml: false !default;

$counterSizeXXS: componentSize(xxs);
$counterSizeXS: componentSize(xs);

@if ($includeHtml) {
  .sg-counter {
    border-radius: $borderRadiusLarge;
    padding: 0 spacing(xs);
    min-width: $counterSizeXS;
    min-height: $counterSizeXS;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    flex-shrink: 0;
    background-color: $red-50;
    cursor: default;

    &__text {
      position: relative;
      top: 1px;
    }

    &__text-spaced {
      position: relative;
      top: 3px;
    }

    &--red-50 {
      background-color: $red-50;
    }

    &--blue-50 {
      background-color: $blue-50;
    }

    &--with-icon {
      height: $counterSizeXS;
      padding: 0;
      border-radius: $counterSizeXS;
      padding-right: spacing(xs);
      background: $gray-20;
      position: relative;
      vertical-align: top;

      &.sg-counter--xxs {
        min-height: $counterSizeXXS;
        min-width: $counterSizeXXS;
        height: $counterSizeXXS;
        width: auto;
        border-radius: $counterSizeXXS;
        padding: 0 spacing(xs) 0 0;
      }
    }

    &--xxs {
      min-height: $counterSizeXXS;
      min-width: $counterSizeXXS;
      height: $counterSizeXXS;
      padding: 0 spacing(xxs);
      border-radius: $counterSizeXXS;
    }

    &__icon {
      z-index: 2;
    }

    &__icon-container {
      z-index: 1;
      margin-right: spacing(xxs);

      &::before {
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        left: 2px;
        top: 2px;
        background-color: $white;
      }

      &--xxs {
        &::before {
          height: 12px;
          width: 12px;
          border-radius: 6px;
        }
      }
    }

    &--with-animation {
      transform: scale(0);
      animation: sgCounterAnimation 0.6s 2s forwards;
    }
  }

  @keyframes sgCounterAnimation {
    0% {
      transform: scale(0);
    }

    80% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
}
