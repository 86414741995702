.sg-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: componentSize(xs);
  border-radius: 6px;
  padding: spacing(xs);
  cursor: default;
  max-width: 100%;

  &__icon {
    margin-right: spacing(xs);
  }

  &__text {
    position: relative;
    top: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--closable {
    padding: spacing(xs) spacing(xxs) spacing(xs) spacing(xs);
  }

  &__close-button {
    background: none;
    border: none;
    padding: 0;
    margin-left: 6px;
  }

  &--transparent {
    padding-left: 0;
    padding-right: 0;
  }

  &--black {
    background-color: $black;
  }

  &--white {
    background-color: $white;
  }

  &--blue-50 {
    background-color: $blue-50;
  }

  &--green-50 {
    background-color: $green-50;
  }

  &--indigo-40 {
    background-color: $indigo-40;
  }

  &--red-50 {
    background-color: $red-50;
  }

  &--yellow-50 {
    background-color: $yellow-50;
  }

  &--gray-50 {
    background-color: $gray-50;
  }

  &--blue-20 {
    background-color: $blue-20;
  }

  &--green-20 {
    background-color: $green-20;
  }

  &--indigo-20 {
    background-color: $indigo-20;
  }

  &--red-20 {
    background-color: $red-20;
  }

  &--yellow-20 {
    background-color: $yellow-20;
  }

  &--gray-20 {
    background-color: $gray-20;
  }
}
