$includeHtml: false !default;

@if ($includeHtml) {
  .sg-select {
    display: inline-block;
    position: relative;
    text-overflow: ellipsis;
    margin: 0;

    &__element {
      display: inline-block;
      padding: 0 36px 0 spacing(s);
      appearance: none;
      width: 100%;
      border: 2px solid $gray-20;
      font-size: $formElementDefaultFontSize;
      color: $formElementPlacholderTextColor;
      height: $formElementSizeM;
      background: $formElementDefaultBackgroundColor;
      border-radius: $formElementStandardBorderRadius;

      &::-ms-expand {
        display: none;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $gray-20;
      }
    }

    &__icon {
      position: absolute;
      top: spacing(xs) + 4px;
      right: spacing(xs) + 4px;
      pointer-events: none;
      z-index: 1;
      display: flex;
    }

    &--white {
      .sg-select__element {
        background: $white;
        border: 2px solid $white;
      }
    }

    &.sg-select--white {
      &.sg-select--valid {
        &:hover {
          .sg-select__element {
            background-color: mix($white, $gray-10, 12%);
            border: 2px solid $formElementValidColor;
          }
        }
      }

      &.sg-select--invalid {
        &:hover {
          .sg-select__element {
            background-color: mix($white, $gray-10, 12%);
            border: 2px solid $formElementInvalidColor;
          }
        }
      }

      &:hover {
        .sg-select__element {
          background-color: mix($white, $gray-10, 12%);
          border: 2px solid mix($white, $gray-10, 12%);
        }
      }
    }

    &:hover {
      .sg-select__element {
        background-color: mix(
          $formElementDefaultBackgroundColor,
          $gray-40,
          80%
        );
        border: 2px
          solid
          mix($formElementDefaultBackgroundColor, $gray-40, 80%);
      }
    }

    &--full-width {
      width: 100%;
    }

    &--l {
      .sg-select__icon {
        top: spacing(s);
        right: spacing(s);
      }

      .sg-select__element {
        height: $formElementSizeL;
        font-size: $formElementLargeFontSize;
        border-radius: $formElementLargeBorderRadius;
        padding: 0 50px 0 spacing(m);

        &::-ms-expand {
          display: none;
        }
      }

      &::placeholder {
        font-size: $formElementLargeFontSize;
      }
    }

    &--selected,
    &--valid,
    &--invalid {
      .sg-select__element {
        color: $black;
      }
    }

    &--valid {
      .sg-select__element {
        font-weight: normal;
        border: 2px solid $formElementValidColor;
      }

      &:hover {
        .sg-select__element {
          background-color: mix(
            $formElementDefaultBackgroundColor,
            $gray-40,
            80%
          );
          border: 2px solid $formElementValidColor;
        }
      }
    }

    &--invalid {
      .sg-select__element {
        font-weight: normal;
        border: 2px solid $formElementInvalidColor;
      }

      &:hover {
        .sg-select__element {
          background-color: mix(
            $formElementDefaultBackgroundColor,
            $gray-40,
            80%
          );
          border: 2px solid $formElementInvalidColor;
        }
      }
    }

    &--capitalized {
      .sg-select__element {
        text-transform: capitalize;
      }
    }
  }
}
