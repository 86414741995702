@mixin iconColor() {
  color: $white;
  fill: currentColor;

  @media (forced-colors: active) {
    fill: CanvasText;
  }

  &--adaptive {
    color: inherit;
  }

  &--icon-black {
    color: $icon-black;
  }

  &--icon-white {
    color: $icon-white;
  }

  &--icon-gray-70 {
    color: $icon-gray-70;
  }

  &--icon-gray-50 {
    color: $icon-gray-50;
  }

  &--icon-gray-40 {
    color: $icon-gray-40;
  }

  &--icon-blue-50 {
    color: $icon-blue-50;
  }

  &--icon-indigo-50 {
    color: $icon-indigo-50;
  }

  &--icon-green-50 {
    color: $icon-green-50;
  }

  &--icon-yellow-50 {
    color: $icon-yellow-50;
  }

  &--icon-red-50 {
    color: $icon-red-50;
  }
}
