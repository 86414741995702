$boxBorderSize: 2px;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-box {
    width: 100%;
    border-radius: $borderRadiusDefault;

    &--border {
      border: $boxBorderSize solid $gray-20;
    }

    &--shadow {
      box-shadow: $shadowMedium;
    }

    &--no-border-radius {
      border-radius: 0;
    }

    &--padding-xxs {
      padding: spacing(xxs);
    }

    &--padding-xxs#{&}--border {
      padding: (spacing(xxs) - $boxBorderSize);
    }

    &--padding-xs {
      padding: spacing(xs);
    }

    &--padding-xs#{&}--border {
      padding: (spacing(xs) - $boxBorderSize);
    }

    &--padding-s {
      padding: spacing(s);
    }

    &--padding-s#{&}--border {
      padding: (spacing(s) - $boxBorderSize);
    }

    &--padding-m {
      padding: spacing(m);
    }

    &--padding-m#{&}--border {
      padding: (spacing(m) - $boxBorderSize);
    }

    &--padding-l {
      padding: spacing(l);
    }

    &--padding-l#{&}--border {
      padding: (spacing(l) - $boxBorderSize);
    }

    &--padding-xl {
      padding: spacing(xl);
    }

    &--padding-xl#{&}--border {
      padding: (spacing(xl) - $boxBorderSize);
    }

    // Background colors
    &--transparent {
      background-color: transparent;
    }

    &--white {
      background: $white;
    }

    &--gray-40 {
      background: $gray-40;
    }

    &--gray-20 {
      background-color: $gray-20;
    }

    &--gray-10 {
      background-color: $gray-10;
    }

    &--blue-40 {
      background-color: $blue-40;
    }

    &--blue-30 {
      background-color: $blue-30;
    }

    &--blue-20 {
      background-color: $blue-20;
    }

    &--green-40 {
      background-color: $green-40;
    }

    &--green-30 {
      background-color: $green-30;
    }

    &--green-20 {
      background-color: $green-20;
    }

    &--green-10 {
      background-color: $green-10;
    }

    &--indigo-40 {
      background-color: $indigo-40;
    }

    &--indigo-20 {
      background-color: $indigo-20;
    }

    &--indigo-10 {
      background-color: $indigo-10;
    }

    &--red-40 {
      background-color: $red-40;
    }

    &--red-30 {
      background-color: $red-30;
    }

    &--red-20 {
      background-color: $red-20;
    }

    &--yellow-40 {
      background-color: $yellow-40;
    }

    &--yellow-20 {
      background-color: $yellow-20;
    }

    // Border colors
    &--border-color-transparent {
      border-color: transparent;
    }

    &--border-color-white {
      border-color: $white;
    }

    &--border-color-gray-40 {
      border-color: $gray-40;
    }

    &--border-color-gray-20 {
      border-color: $gray-20;
    }

    &--border-color-gray-10 {
      border-color: $gray-10;
    }

    &--border-color-blue-40 {
      border-color: $blue-40;
    }

    &--border-color-blue-30 {
      border-color: $blue-30;
    }

    &--border-color-blue-20 {
      border-color: $blue-20;
    }

    &--border-color-green-40 {
      border-color: $green-40;
    }

    &--border-color-green-30 {
      border-color: $green-30;
    }

    &--border-color-green-20 {
      border-color: $green-20;
    }

    &--border-color-green-10 {
      border-color: $green-10;
    }

    &--border-color-indigo-40 {
      border-color: $indigo-40;
    }

    &--border-color-indigo-20 {
      border-color: $indigo-20;
    }

    &--border-color-indigo-10 {
      border-color: $indigo-10;
    }

    &--border-color-red-40 {
      border-color: $red-40;
    }

    &--border-color-red-30 {
      border-color: $red-30;
    }

    &--border-color-red-20 {
      border-color: $red-20;
    }

    &--border-color-yellow-40 {
      border-color: $yellow-40;
    }

    &--border-color-yellow-20 {
      border-color: $yellow-20;
    }
  }
}
