$homeButtonMobileHeight: 32px;
$homeButtonDefaultHeight: 36px;
$homeButtonDefaultWidth: 81px;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-home-button {
    @include component();

    @include sgBreakpoint(large-only) {
      top: 2px;
    }

    &__logo-small {
      border: none;
      display: block;
      width: $homeButtonMobileHeight;
      height: $homeButtonMobileHeight;

      @include sgBreakpoint(large-only) {
        display: none;
      }
    }

    &__logo-big {
      height: $homeButtonDefaultHeight;
      width: $homeButtonDefaultWidth;
      display: none;

      @include sgBreakpoint(large-only) {
        display: block;
      }
    }

    &--brainly-plus &__logo-big {
      height: 41px;
      width: 62px;
    }

    &--eodev &__logo-big {
      width: 68px;
    }

    &--nosdevoirs &__logo-big {
      width: 97px;
    }

    &--znanija &__logo-big {
      width: 78px;
    }
  }
}
