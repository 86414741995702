$includeHtml: false !default;

@if ($includeHtml) {
  .sg-search {
    @include component();
    overflow: visible;

    &__icon {
      transition: color 0.3s ease-out;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      background: none;
      border: none;
      right: 5px;
    }

    &__input {
      width: 100%;
    }

    &__input:focus + &__icon {
      color: $gray-50;
    }

    &--full-width {
      width: 100%;
    }

    &--l {
      .sg-search__icon {
        right: spacing(xs);
      }
    }
  }
  // Normalize adds -webkit-appearance: textfield and that changes styling for iOS
  input[type='search'].sg-search__input {
    -webkit-appearance: none;
  }
}
