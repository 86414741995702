$radioSizeXXS: componentSize(xxs);
$radioSizeXS: componentSize(xs);
$radioSizeS: componentSize(s);
$crInputColor: $white;
$crInputBorderColor: $gray-40;
$crInputActiveBorderColor: $blue-40;
$crInputCheckedColor: $blue-40;
$crRadioCheckedColor: $white;
$crInputActiveColor: rgba($crInputColor, 0.7);

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-checkbox,
  .sg-radio {
    @include component();
    overflow: visible;
    height: $radioSizeXS;
    min-height: $radioSizeXS;
    display: inline-flex;
    align-items: center;
    padding: spacing(xxs) 0;

    &__element {
      opacity: 0;
      position: absolute;
      margin: 0;
      width: $radioSizeXXS;
      height: $radioSizeXXS;
      z-index: 1;
    }

    &__element:active + &__ghost {
      border-color: $crInputActiveBorderColor;
    }

    &__ghost {
      background: $crInputColor;
      width: $radioSizeXXS;
      height: $radioSizeXXS;
      border: 2px solid $crInputBorderColor;
      color: $crInputColor;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex: none;
    }

    &__label {
      margin-left: 16px;
    }
  }

  .sg-checkbox {
    &__ghost {
      border-radius: 20%;
    }

    &__element:checked {
      & + .sg-checkbox__ghost {
        border: none;
        background: $crInputCheckedColor;
      }

      &:active + .sg-checkbox__ghost::before {
        color: $crInputActiveColor;
      }
    }
  }

  .sg-radio {
    &__ghost {
      border-radius: 50%;

      &::before {
        content: '';
        border-radius: 50%;
        background-color: $crInputColor;
        width: $radioSizeXXS / 2;
        height: $radioSizeXXS / 2;
      }
    }

    &__element:checked {
      & + .sg-radio__ghost {
        border-color: $crInputCheckedColor;
        background: $crRadioCheckedColor;
      }

      & + .sg-radio__ghost::before {
        background: $crInputCheckedColor;
      }
    }

    &--s {
      height: $radioSizeS;
      min-height: $radioSizeS;
      line-height: $radioSizeS;

      .sg-radio__element {
        left: 0;
        width: $radioSizeS;
        height: $radioSizeS;
      }

      .sg-radio__ghost {
        width: $radioSizeS;
        height: $radioSizeS;

        &::before {
          width: $radioSizeS / 2;
          height: $radioSizeS / 2;
        }
      }
    }
  }
}
