$flexSpacingsMap: (
  'xxs': spacing(xxs),
  'xs': spacing(xs),
  's': spacing(s),
  'm': spacing(m),
  'l': spacing(l),
  'xl': spacing(xl),
  'xxl': spacing(xxl),
  'xxxl': spacing(xxxl),
  'xxxxl': spacing(xxxxl),
);

$marginSides: (top right bottom left);

$flexProps: (justify-content align-items align-self);
$flexJustifyValues: (space-between space-evenly space-around);
$flexAlignmentValues: (center flex-start flex-end baseline stretch);

.sg-flex {
  display: flex;
}

@each $breakpoint, $variant in $responsiveVariants {
  @include sgResponsive($breakpoint) {
    @include makeResponsive($variant, 'sg-flex--full-width') {
      width: 100%;
    }

    @include makeResponsive($variant, 'sg-flex--auto-width') {
      width: auto;
    }

    @include makeResponsive($variant, 'sg-flex--full-height') {
      height: 100%;
    }

    @include makeResponsive($variant, 'sg-flex--auto-height') {
      height: auto;
    }

    @include makeResponsive($variant, 'sg-flex--no-shrink') {
      flex-shrink: 0;
    }

    @include makeResponsive($variant, 'sg-flex--shrink-1') {
      flex-shrink: 1;
    }

    @include makeResponsive($variant, 'sg-flex--inline') {
      display: inline-flex;
    }

    @include makeResponsive($variant, 'sg-flex--flex') {
      display: flex;
    }

    @include makeResponsive($variant, 'sg-flex--wrap') {
      flex-wrap: wrap;
    }

    @include makeResponsive($variant, 'sg-flex--wrap-reverse') {
      flex-wrap: wrap-reverse;
    }

    @include makeResponsive($variant, 'sg-flex--nowrap') {
      flex-wrap: nowrap;
    }

    @include makeResponsive($variant, 'sg-flex--column') {
      flex-direction: column;
    }

    @include makeResponsive($variant, 'sg-flex--column-reverse') {
      flex-direction: column-reverse;
    }

    @include makeResponsive($variant, 'sg-flex--row') {
      flex-direction: row;
    }

    @include makeResponsive($variant, 'sg-flex--row-reverse') {
      flex-direction: row-reverse;
    }

    @each $value in $flexJustifyValues {
      @include makeResponsive($variant, 'sg-flex--justify-content-#{$value}') {
        justify-content: #{$value};
      }
    }

    @each $propName in $flexProps {
      @each $propValue in $flexAlignmentValues {
        @include makeResponsive(
          $variant,
          'sg-flex--#{$propName}-#{$propValue}'
        ) {
          #{$propName}: #{$propValue};
        }
      }
    }

    @each $sizeName, $size in $flexSpacingsMap {
      @include makeResponsive($variant, 'sg-flex--margin-#{$sizeName}') {
        margin: #{$size};
      }
    }

    @each $sizeName, $size in $flexSpacingsMap {
      @each $side in $marginSides {
        @include makeResponsive(
          $variant,
          'sg-flex--margin-#{$side}-#{$sizeName}'
        ) {
          margin-#{$side}: #{$size};
        }
      }
    }
  }
}

// helper class just for add spacing documentation into SG
.doc-spacing {
  background-color: $blue-30;
  margin-right: spacing(xl);

  @each $sizeName, $size in $flexSpacingsMap {
    &--#{$sizeName} {
      width: #{$size};
      height: #{$size};
    }
  }
}
