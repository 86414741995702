$footerBackgroundColorSmall: $gray-20;
$footerBackgroundColorLarge: $white;

$footerLayoutMaximumWidth: 1024px;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-footer {
    background-color: $footerBackgroundColorSmall;

    @include sgBreakpoint(medium-up) {
      background-color: $footerBackgroundColorLarge;
    }

    &__container {
      position: relative;
      padding: gutter(1);

      @include sgBreakpoint(medium-up) {
        max-width: $footerLayoutMaximumWidth;
        margin-left: auto;
        margin-right: auto;

        padding: rhythm(1) gutter(1);
      }
    }

    &__line {
      margin-bottom: rhythm(1);

      @include sgBreakpoint(medium-up) {
        margin-bottom: rhythm(1);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
