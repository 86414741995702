@import './text-colors-mixin';

$includeHtml: false !default;

$bitSizes: (
  xlarge: 80px,
  large: 56px,
  medium: 40px,
  small: 24px,
);

@function getBitSize($fontsizeKey) {
  @return map-get($bitSizes, $fontsizeKey);
}

@if ($includeHtml) {
  .sg-text-bit {
    @include uppercaseText(-0.0625rem);
    font-size: getBitSize(medium);
    line-height: getBitSize(medium);
    color: $text-green-40;
    font-weight: $fontWeightBlack;
    margin: 0;
    position: relative;

    &--inherited {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-weight: inherit;
    }

    @include textColor();

    &--small {
      @include uppercaseText(0);
      font-size: getBitSize(small);
      line-height: getBitSize(small);
    }

    &--large {
      font-size: getBitSize(large);
      line-height: getBitSize(large);
    }

    &--xlarge {
      font-size: getBitSize(xlarge);
      line-height: getBitSize(xlarge);
    }
  }

  @each $breakpoint, $variant in $responsiveVariants {
    @include sgResponsive($breakpoint) {
      @each $size in $bitSizes {
        @include makeResponsive($variant, 'sg-text-bit--#{size}') {
          font-size: $size;
        }
      }
    }
  }
}
