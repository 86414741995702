$cardBorderColor: $gray-40;
$cardBorderSize: 1px;
$cardShadow: $shadowSmall;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-card {
    @include component();
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: $borderRadiusDefault;
    border: $cardBorderColor solid $cardBorderSize;
    overflow: hidden;
    background-color: $white;

    &--transparent {
      background-color: transparent;
    }

    &--full {
      width: 100%;

      .sg-card__hole {
        width: 100%;
      }
    }

    &--vertical {
      flex-direction: row;

      .sg-card__hole {
        align-self: stretch;
        flex: 1;
      }
    }

    &--with-shadow {
      box-shadow: $cardShadow;
    }

    &--no-border {
      border: none;
    }

    &--centered {
      .sg-card__hole {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }

    &--padding-small {
      .sg-card__hole {
        padding: gutter(2 / 3);
      }
    }

    &--padding-normal {
      .sg-card__hole {
        padding: gutter(1);
      }
    }

    &--padding-large {
      .sg-card__hole {
        padding: gutter(4 / 3);
      }
    }

    &--padding-xlarge {
      .sg-card__hole {
        padding: gutter(8 / 3);
      }
    }

    &__hole {
      width: 100%;

      &--blue-40 {
        background-color: $blue-40;
      }

      &--blue-30 {
        background-color: $blue-30;
      }

      &--blue-20 {
        background-color: $blue-20;
      }

      &--indigo-40 {
        background-color: $indigo-40;
      }

      &--indigo-30 {
        background-color: $indigo-30;
      }

      &--indigo-20 {
        background-color: $indigo-20;
      }

      &--green-40 {
        background-color: $green-40;
      }

      &--green-30 {
        background-color: $green-30;
      }

      &--green-20 {
        background-color: $green-20;
      }

      &--yellow-40 {
        background-color: $yellow-40;
      }

      &--yellow-30 {
        background-color: $yellow-30;
      }

      &--yellow-20 {
        background-color: $yellow-20;
      }

      &--gray-70 {
        background-color: $gray-70;
      }

      &--gray-50 {
        background-color: $gray-50;
      }

      &--gray-40 {
        background-color: $gray-40;
      }

      &--gray-20 {
        background-color: $gray-20;
      }

      &--gray-10 {
        background-color: $gray-10;
      }
    }
  }
}
