$dialogSmallPadding: spacing(m);
$dialogMediumPadding: spacing(l);
$dialogOffsetForCloseButton: 48px;
$dialogBoxShadow: $shadowLarge;
$dialogOverlayBackgroundColor: rgba(0, 0, 0, 0.3);

$dialogSlideWindowDistance: 32px;
$dialogSlideFullscreenSmallDistance: 24px;
$dialogSlideFullscreenMediumDistance: 38px;

$dialogMaxWidthMap: (
  's': 392px,
  'm': 600px,
  'l': 808px,
  'xl': 1016px,
);

.sg-dialog {
  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include sgResponsive(md) {
      padding: spacing(m);
    }

    &::before {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $dialogOverlayBackgroundColor;
      transition-property: opacity;
      transition-duration: $durationQuick2;
      transition-timing-function: $easingLinear;
      opacity: 0;
    }

    &--open {
      &::before {
        opacity: 1;
      }
    }

    &--scroll {
      overflow: auto;
    }

    &--fullscreen {
      padding: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $white;
    border-radius: $borderRadiusDefault $borderRadiusDefault 0 0;
    box-shadow: $dialogBoxShadow;
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
    transition-property: transform, opacity;
    transition-duration: $durationModerate2, $durationQuick2;
    transition-timing-function: $easingExit, $easingLinear;
    transform: translate3d(0, $dialogSlideWindowDistance, 0);
    opacity: 0;

    @include sgResponsive(md) {
      border-radius: $borderRadiusDefault;
      margin-bottom: auto;

      @each $sizeName, $maxWidth in $dialogMaxWidthMap {
        &--size-#{$sizeName} {
          max-width: #{$maxWidth};
        }
      }
    }

    &--size-fullscreen {
      transition-duration: $durationGentle1, $durationQuick2;
      transform: translate3d(0, $dialogSlideFullscreenSmallDistance, 0);
      border-radius: 0;
      box-shadow: none;
      max-width: 100%;
      min-height: 100%;

      @include sgResponsive(md) {
        transform: translate3d(0, $dialogSlideFullscreenMediumDistance, 0);
      }
    }

    &--scroll {
      overflow: auto;
      max-height: 100%;
    }

    &--exiting,
    &--reduce-motion {
      transform: translate3d(0, 0, 0);
    }

    @media screen and (prefers-reduced-motion: reduce) {
      transform: translate3d(0, 0, 0);
    }

    &--open {
      transition-timing-function: $easingEntry, $easingLinear;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  &__header {
    padding-top: $dialogSmallPadding;
    padding-left: $dialogSmallPadding;
    padding-right: $dialogOffsetForCloseButton;

    @include sgResponsive(md) {
      padding-top: $dialogMediumPadding;
      padding-left: $dialogMediumPadding;
      padding-right: $dialogOffsetForCloseButton;
    }
  }

  &__body {
    overflow: auto;
    padding: $dialogSmallPadding;

    @include sgResponsive(md) {
      padding: $dialogMediumPadding;
    }
  }

  &__header + &__body {
    padding-top: 0;
  }

  &__close-button {
    margin: spacing(xs);
    position: absolute;
    top: 0;
    right: 0;
  }
}
