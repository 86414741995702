@if ($includeHtml) {
  .sg-accordion-item {
    overflow-anchor: none;

    &--no-gap {
      border-radius: 0;
      border-top: none;

      &:first-child {
        border-top: 2px solid $gray-20;
        border-top-left-radius: $borderRadiusDefault;
        border-top-right-radius: $borderRadiusDefault;
      }

      &:last-child {
        border-bottom-left-radius: $borderRadiusDefault;
        border-bottom-right-radius: $borderRadiusDefault;
      }
    }

    &__button {
      cursor: pointer;

      &--focused {
        &:focus:not(:focus-visible) {
          outline: none;
        }
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    &__title {
      flex: auto;
    }

    &__content {
      transition: 0.2s ease-out;
      transition-property: height;
      height: 0;
      overflow: hidden;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    &__content-box {
      padding-top: 0;
    }

    &__arrow {
      transition: 0.2s ease-out transform;
      transform: rotateX(0);

      &--visible {
        transform: rotate(180deg);
      }
    }

    &__icon {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      flex: none;

      &--hover {
        background: $gray-20;
      }
    }
  }
}
