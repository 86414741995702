$logoDefaultHeight: 60px;
$logoDefaultWidth: 134px;
$logoLargeHeight: 81px;
$logoLargeWidth: 180px;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-logo {
    @include component();
    height: $logoDefaultHeight;
    width: $logoDefaultWidth;

    @include sgResponsive(md) {
      height: $logoLargeHeight;
      width: $logoLargeWidth;
    }

    &--brainly-plus-small,
    &--znanija-plus-small {
      width: $logoDefaultWidth;
      height: 68px;

      @include sgResponsive(md) {
        width: $logoLargeWidth;
        height: 92px;
      }
    }

    &--brainly-plus,
    &--brainly-plus-inverse,
    &--znanija-plus,
    &--znanija-plus-inverse,
    &--brainly-tutoring {
      width: $logoDefaultWidth;
      height: 90px;

      @include sgResponsive(md) {
        width: $logoLargeWidth;
        height: 120px;
      }
    }

    &--brainly-tutoring-small {
      width: $logoDefaultWidth;
      height: 72px;

      @include sgResponsive(md) {
        width: $logoLargeWidth;
        height: 96px;
      }
    }

    &--eodev {
      width: 114px;

      @include sgResponsive(md) {
        width: 154px;
      }
    }

    &--nosdevoirs {
      width: 161px;

      @include sgResponsive(md) {
        width: 217px;
      }
    }

    &--znanija {
      width: 131px;

      @include sgResponsive(md) {
        width: 176px;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
    }
  }
}
